
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import { start } from 'turbolinks';

const EasingFunctions = {
  // no easing, no acceleration
  linear: t => t,
  // accelerating from zero velocity
  easeInQuad: t => t*t,
  // decelerating to zero velocity
  easeOutQuad: t => t*(2-t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
  // accelerating from zero velocity 
  easeInCubic: t => t*t*t,
  // decelerating to zero velocity 
  easeOutCubic: t => (--t)*t*t+1,
  // acceleration until halfway, then deceleration 
  easeInOutCubic: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
  // accelerating from zero velocity 
  easeInQuart: t => t*t*t*t,
  // decelerating to zero velocity 
  easeOutQuart: t => 1-(--t)*t*t*t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
  // accelerating from zero velocity
  easeInQuint: t => t*t*t*t*t,
  // decelerating to zero velocity
  easeOutQuint: t => 1+(--t)*t*t*t*t,
  // acceleration until halfway, then deceleration 
  easeInOutQuint: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
}

const Map = {
  initialize() {
    if ($('#mapbox').length > 0) {

      mapboxgl.accessToken = 'pk.eyJ1IjoiY3B1IiwiYSI6ImNrZ2pvaWxmajBpbjcydXBpdDJiaDdraGcifQ.rS_2uRDxnuw6Xaw6eKbvLw';
      var map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/cpu/ckgjoj4qb1lv719mtkci5pynv/draft',
        compact: true,
        center: [-74.035695, 40.700978],
        zoom: 11.8,
        bearing: -12.09,
        pitch: 60,
        // attributionControl: false,
        interactive: false,
      });

      map.scrollZoom.disable();
      map.dragPan.disable();
      map.doubleClickZoom.disable();
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();

      var size = 15;
      var pulseDelayMin = 5;
      var pulseDelayMax = 30;

      $.get('/hotspots.json').done(function(response) {
        for (var i in response.data)
        {
          var el = document.createElement('div');
          el.className = 'marker';
          el.style.animationDelay = ((Math.random() * (pulseDelayMax - pulseDelayMin)) + pulseDelayMin) + 's';
          el.style.width = size + 'px';
          el.style.height = size + 'px';

          if (response.data[i].lng != undefined)
          {
            var lnglat = [ response.data[i].lng, response.data[i].lat ];
            new mapboxgl.Marker(el).setLngLat(lnglat).addTo(map);

          }
        }
      });

      map.on("load", function() {
         
        // Let's fly to LA!
        var animationOptions = {
          duration: 10 * 60 * 1000,
          easing: EasingFunctions.easeInOutQuad,
          offset: [0, 0],
          animate: true,
          essential: true, // animation will happen even if user has `prefers-reduced-motion` setting on
          curve: 0.4,
          center: [ 
            -118.207128, 33.991622
          ]
        };
        map.flyTo(animationOptions);
      })
    }
  }
}


export default Map;
