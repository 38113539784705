// import * as ActiveStorage from '@rails/activestorage'
// import Cookies from 'js-cookie'
// import pluralize from 'pluralize'
// import URI from 'urijs'
// import stickybits from 'stickybits';


import CountrySelect from './country_select';
import Map from './map';

const Loris = {
  initialize() {
    jQuery(function() {
      CountrySelect.initialize();
      Map.initialize();
    });
  }
};

export default Loris;