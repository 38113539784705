const CountrySelect = {
  initialize() {
    if ($('select.is-country-select').length > 0) {
      $('select.is-country-select').on('change', function(e) {
        this.onCountrySelect($(e.target).val(), $(e.target).data('url'));
      }.bind(this));
    }
  },

  onCountrySelect(country_code, url) {
    if (country_code == 'US') {
      $('form').addClass('is-us').removeClass('is-foreign');
    }
    else {
      $('form').addClass('is-foreign').removeClass('is-us');
    }
    
    $.get({
      url: url,
      data: {
        country_code: country_code
      }
    });
  } 
}

export default CountrySelect;
